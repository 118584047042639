import Image from 'next/image'
import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IntoUrl } from '@models/IntoUrl'
import { selectImageLoaded, setImageLoaded } from '@redux/slices/mobileSlice'
import { RootState } from '@redux/store/store'

export type ProgressiveImageState = 'blur' | 'thumbnail' | 'video'

interface ProgressiveImageProps {
  src: string
  blurPlaceholder: string
  alt?: string
  contentSize?: IntoUrl['meta']['contentSize']
  setImageLoadState: (state: ProgressiveImageState) => void
  imageLoadState: ProgressiveImageState
  className?: string
}

export const ProgressiveImage = ({
  src,
  blurPlaceholder,
  alt = '',
  contentSize,
  setImageLoadState,
  imageLoadState,
  className,
}: ProgressiveImageProps): ReactElement => {
  const dispatch = useDispatch()
  const imageLoaded = useSelector((state: RootState) => selectImageLoaded(state, src))

  useEffect(() => {
    if (imageLoaded) {
      // If the image is already loaded, update the imageLoadState accordingly.
      setImageLoadState('thumbnail')
    }
  }, [imageLoaded, setImageLoadState])

  const onThumbLoad = useCallback(() => {
    setImageLoadState('thumbnail')
  }, [setImageLoadState])

  const onFullImageLoad = useCallback(() => {
    dispatch(setImageLoaded({ urlId: src }))
  }, [dispatch, src])

  return (
    <>
      {/*
      This first image is used to provide the aspect ratio to the image. Therefore, we try to load 
      the smallest possible image first (i.e, the tiny thumbnail).
      Once the primary thumbnail finishes loading, we will make the tiny thumbnail invisible but keep 
      using its dimensions in DOM for the aspect ratio.
       */}
      {!imageLoaded && (
        <Image
          priority
          src={blurPlaceholder}
          alt={alt}
          width={contentSize?.width ?? 0}
          height={contentSize?.height ?? 0}
          className={`h-auto max-h-full w-full object-contain blur-sm transition-opacity duration-100 ${
            imageLoadState === 'blur' ? 'opacity-100' : 'opacity-0'
          } ${className ?? ''}`}
        />
      )}
      <Image
        priority
        src={src}
        alt={alt}
        width={contentSize?.width ?? 0}
        height={contentSize?.height ?? 0}
        blurDataURL={blurPlaceholder}
        onLoad={() => {
          onThumbLoad()
          onFullImageLoad()
        }}
        className={`${
          !imageLoaded ? 'absolute left-0 top-0' : ''
        } size-full object-contain transition-opacity duration-75 ${
          imageLoadState === 'thumbnail' || imageLoaded ? 'opacity-100' : 'opacity-10'
        } ${className ?? ''}`}
      />
    </>
  )
}

export const ProgressiveImageWithManagedState: FC<
  Omit<ProgressiveImageProps, 'setImageLoadState' | 'imageLoadState'>
> = props => {
  const [imageLoadState, setImageLoadState] = useState<ProgressiveImageState>('blur')
  return <ProgressiveImage {...props} setImageLoadState={setImageLoadState} imageLoadState={imageLoadState} />
}
