import { useCallback } from 'react'
import { useImmer } from 'use-immer'

export const useVisibleItems = () => {
  const [visibleSet, setVisibleSet] = useImmer<Set<number>>(new Set())
  const [maxIndexSeen, setMaxIndexSeen] = useImmer(0)

  const onItemViewed = useCallback(
    (position: number, isInView: boolean) => {
      setVisibleSet(s => {
        if (isInView) s.add(position)
        else s.delete(position)
      })
      if (isInView) {
        setMaxIndexSeen(draft => Math.max(draft, position))
      }
    },
    [setMaxIndexSeen, setVisibleSet]
  )
  return { onItemVisibilityChanged: onItemViewed, numItemsInViewport: visibleSet.size, maxIndexSeen }
}
