import Long from 'long'
import { useCallback } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { commands, globals } from '@lib/messages/protobuf'
import MixMix from '@services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import TrackContentSelection = commands.TrackContentSelection
import ContentSelectionType = globals.ContentSelectionType

export const useTrackContentSelection = () => {
  const { currentUserID } = useCurrentUserContext()
  return useCallback(
    (opts: {
      urlID: string
      index: number
      maxIndexSeen: number
      numItemsInViewport: number
      isMetaKeyPressed: boolean
    }) => {
      const createMessage = (context: commands.CommandContext) => {
        return new TrackContentSelection({
          maxIndexSeen: opts.maxIndexSeen,
          numItemsInViewport: opts.numItemsInViewport,
          selectedIndex: opts.index,
          selectionType: opts.isMetaKeyPressed ? ContentSelectionType.LONG_PRESS : ContentSelectionType.SINGLE_PRESS,
          urlId: Long.fromString(opts.urlID),
          userId: currentUserID ? Long.fromNumber(currentUserID) : null,
          context,
        })
      }

      const initialConfig = MixMix.commands.trackContentSelection({})
      void broadcastTrackingMessage('TrackContentSelection', initialConfig, createMessage).catch(() => {})
    },
    [currentUserID]
  )
}
